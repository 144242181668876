<template>
  <div id="ShopCartMobile">
    <div class="MainContainer">
      <div class="topHeader">
        <div class="typeTitle">购物车</div>
        <div class="lineRow"> </div>
      </div>
      <div class="ShopCartHeader">
        <el-checkbox
          class="checkBox"
          size="medium"
          v-model="isCheckAll"
          @change="allCheckEvent"
        ></el-checkbox>
        <div class="ShopCartHeaderText">全选 商品信息</div>
      </div>

      <div class="ShopTypeTitleRow">
        <el-checkbox
          class="checkBox"
          size="medium"
          @change="LaTenAllCheckedChange"
          v-model="isCheckLaTenAll"
        ></el-checkbox>
        <div class="ShopTypeTitleRowText">日本亚马逊</div>
      </div>
      <div class="ShopTypeRow" v-for="it in LaTenChart" :key="it.id">
        <div class="shopTypeTopRow">
          <div class="leftSelectBlock">
            <el-checkbox
              @change="checkboxChange"
              class="checkBox"
              size="medium"
              v-model="it.isChecked"
            ></el-checkbox>
          </div>
          <div class="centerShopImageBlock">
            <div class="storeItemImg">
              <img :src="require(`@/assets/store/Laten/${it.id}.jpg`)" alt="" />
            </div>
          </div>
          <div class="rightInfoBlock">
            <div class="ShopName" @click="clickShopName(it)"> {{ it.name }} </div>

            <div class="ShopPrice"> ￥ {{ it.price }} </div>
            <div class="ShopAmountOptionRow">
              <div class="leftAmountSelect">
                <el-input-number
                  size="mini"
                  v-model="it.sum"
                  :min="1"
                  :max="5"
                  @change="(param) => sumChange(param, it.id)"
                ></el-input-number>
              </div>
              <div class="rightPricePreview"> ￥ {{ totalPrice(it.price, it.sum) }} </div>
            </div>

            <div class="ShopActionRow">
              <div class="optionText"> 待报价 </div>
              <div class="optionText" @click="editMessage(it.id)" v-if="it.message === ''">
                添加备注
              </div>
              <div class="optionText" @click="editMessage(it.id, it.message)" v-else>
                编辑备注
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="splitShopType"></div>

      <div class="ShopTypeTitleRow">
        <el-checkbox
          class="checkBox"
          size="medium"
          @change="YahooAllCheckedChange"
          v-model="isCheckYahooAll"
        ></el-checkbox>
        <div class="ShopTypeTitleRowText"> 日本雅虎</div>
      </div>
      <div class="ShopTypeRow" v-for="it in YahooChart" :key="it.id">
        <div class="shopTypeTopRow">
          <div class="leftSelectBlock">
            <el-checkbox
              @change="checkboxChange"
              class="checkBox"
              size="medium"
              v-model="it.isChecked"
            ></el-checkbox>
          </div>
          <div class="centerShopImageBlock">
            <div class="storeItemImg">
              <img :src="require(`@/assets/store/Yahoo/${it.id}.png`)" alt="" />
            </div>
          </div>
          <div class="rightInfoBlock">
            <div class="ShopName" @click="clickShopName(it)"> {{ it.name }} </div>

            <div class="ShopPrice"> ￥ {{ it.price }} </div>
            <div class="ShopAmountOptionRow">
              <div class="leftAmountSelect">
                <el-input-number
                  size="mini"
                  v-model="it.sum"
                  :min="1"
                  :max="5"
                  @change="(param) => sumChange(param, it.id)"
                ></el-input-number>
              </div>
              <div class="rightPricePreview"> ￥ {{ totalPrice(it.price, it.sum) }} </div>
            </div>

            <div class="ShopActionRow">
              <div class="optionText"> 待报价 </div>
              <div class="optionText" @click="editMessage(it.id)" v-if="it.message === ''">
                添加备注
              </div>
              <div class="optionText" @click="editMessage(it.id, it.message)" v-else>
                编辑备注
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="FooterDeleteBlock">
        <div class="checkedNumBlock">
          {{ `已选 （${isCheckedNum}）` }}
        </div>
        <div class="deleteBlock">
          <img class="deleteIcon" src="@/assets/deleteLogo.png" alt="" @click="deleteHandler" />
          <div class="deleteText" @click="deleteHandler">删除</div>
        </div>
      </div>
      <div class="FooterTotalInfoRow">
        <div class="leftTotal">
          <div class="totalTitle"> 商品总计： </div>
          <div class="totalValue"> ￥ {{ totalPayment }}</div>
        </div>
        <div class="rightTotal">
          <div class="totalTitle"> 运费总计：￥ 00.00 </div>
        </div>
      </div>
      <div class="hasToPayInfoRow">
        <div class="title"> 应付金额： </div>
        <div class="payBlockRow">
          <div class="payMoney"> ￥ {{ totalPayment }} </div>
          <div class="addChartBtn" @click="submitChartHandler"> 结算</div>
        </div>
      </div>
    </div>

    <el-dialog title="备注" :visible.sync="isShowDialog" width="90%" center>
      <div>
        <el-input
          class="textAreaInput"
          type="textarea"
          placeholder="如果有必要，请详细注明大小，颜色，型号等等。"
          v-model="dialogTextArea"
          resize="none"
          :rows="8"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowDialog = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import ShopCartMixin from './ShopCartMixin';
  export default {
    extends: ShopCartMixin,
    name: 'ShopCartMobile',
  };
</script>

<style lang="scss" scoped>
  #ShopCartMobile {
    font-size: 14px;
    .topHeader {
      padding: 11px 16px;
      .typeTitle {
        font-size: 16px;
        font-weight: bold;
      }

      .lineRow {
        width: calc(100% - 16px);
        height: 2px;
        background: #dbdbdb80 0% 0% no-repeat padding-box;
        margin: 10px 0;
      }
    }
    .ShopCartHeader {
      padding: 10px 19px;
      background-color: #fcf1db;
      display: flex;
      align-items: center;
      .ShopCartHeaderText {
        margin-left: 15px;
        font-size: 14px;
      }
    }

    .ShopTypeTitleRow {
      display: flex;
      align-items: center;
      padding: 15px 19px;
      border-bottom: 1px solid #e0e0e0;
      .ShopTypeTitleRowText {
        margin-left: 15px;
      }
    }

    .ShopTypeRow {
      padding: 0 19px;
      border-bottom: 1px solid #e0e0e0;
      .shopTypeTopRow {
        display: flex;
        padding-top: 20px;
        height: fit-content;
        .leftSelectBlock {
          display: flex;
          align-items: center;
          height: 80px;
        }
        .centerShopImageBlock {
          margin: 0 10px;
          margin-top: 0px;
          display: flex;
          align-items: flex-start;
          height: 100%;
          .storeItemImg {
            width: fit-content;
            width: 80px;
            height: 80px;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .rightInfoBlock {
          width: calc(100% - 139px);
          .ShopName {
            width: 100%;
            height: 31px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .ShopPrice {
            padding: 10px 0px;
            color: #6e6e6e;
          }
          .ShopAmountOptionRow {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .leftAmountSelect {
            }
            .rightPricePreview {
              color: #d30000;
              font-size: 16px;
              margin-left: 10px;
              margin-top: 3px;
              white-space: nowrap;
            }
          }

          .ShopActionRow {
            display: flex;
            .optionText {
              font-size: 12px;
              color: #000000;
              margin: 15px 0;
              cursor: pointer;
              &:first-child {
                margin-right: 8px;
              }
            }
          }
        }
      }
    }

    .splitShopType {
      background-color: #e0e0e0;
      width: 100%;
      height: 5px;
    }

    .FooterDeleteBlock {
      padding: 13px 19px;
      display: flex;
      align-items: center;
      .checkedNumBlock {
        margin-right: 10px;
      }
      .deleteBlock {
        display: flex;
        align-items: center;
        .deleteIcon {
          margin-right: 5px;
        }
        .deleteText {
          color: #6e6e6e;
        }
      }
    }

    .FooterTotalInfoRow {
      display: flex;
      flex-wrap: wrap;
      padding: 10px 21px;
      .rightTotal,
      .leftTotal {
        display: flex;
      }
      .leftTotal {
        width: 60%;
      }
      .rightTotal {
        width: 40%;
      }
      .totalTitle {
        font-size: 14px;
        color: #000000;
        width: 40%;
        white-space: nowrap;
      }
      .totalValue {
        width: 60%;
        white-space: nowrap;
      }
    }
    .hasToPayInfoRow {
      padding: 13px 30px;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .payBlockRow {
        display: flex;
        align-items: center;
        .payMoney {
          text-align: center;
          font-size: 18px;
          color: #d30000;
          margin-right: 10px;
        }
        .addChartBtn {
          background: transparent linear-gradient(180deg, #d30000 0%, #910000 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #930000;
          border-radius: 5px;
          color: white;
          font-size: 16px;
          padding: 5px 20px;
          width: 80px;
          float: right;
          cursor: pointer;
          &:hover {
            background: transparent linear-gradient(180deg, #d32a2a 0%, #8f3333 100%) 0% 0%
              no-repeat padding-box;
          }
        }
      }
    }
  }
</style>
