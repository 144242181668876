import { StoreType } from '@/Config';
import { toDecimal } from '@/utils/utils';
const ShopCartMixin = {
  data() {
    return {
      isCheckAll: false,
      isCheckLaTenAll: false,
      isCheckYahooAll: false,
      chartData: [],
      isShowDialog: false,
      dialogTextArea: '',
      openDialogID: null,
    };
  },
  created() {
    this.initChartData();
  },
  computed: {
    getChart() {
      return this.$store.state.carts;
    },
    LaTenChart() {
      return this.chartData.filter((it) => it.web === 'LaTen');
    },
    YahooChart() {
      return this.chartData.filter((it) => it.web === 'Yahoo');
    },
    isCheckedNum() {
      return this.chartData.filter((it) => it.isChecked).length;
    },
    totalPayment() {
      const allCheckPrice = this.chartData.reduce((sum, it) => {
        if (it.isChecked) {
          const totalPrice =
            Math.floor(parseFloat(it.price.replace(/,/g, '')) * it.sum * 100) / 100;
          sum += totalPrice;
        }
        return sum;
      }, 0);

      return toDecimal(allCheckPrice);
    },
  },
  methods: {
    initChartData() {
      this.chartData = this.getChart.slice().map((it) => {
        it.isChecked = false;
        return it;
      });
      this.chartData = this.chartData.slice();
    },
    LaTenAllCheckedChange(param) {
      this.chartData
        .filter((it) => it.web === 'LaTen')
        .map((it) => {
          it.isChecked = param;
          return it;
        });
      this.chartData = this.chartData.slice();
    },
    YahooAllCheckedChange(param) {
      this.chartData
        .filter((it) => it.web === 'Yahoo')
        .map((it) => {
          it.isChecked = param;
          return it;
        });
      this.chartData = this.chartData.slice();
    },
    allCheckEvent(param) {
      if (param) {
        this.isCheckLaTenAll = true;
        this.isCheckYahooAll = true;
      } else {
        this.isCheckLaTenAll = false;
        this.isCheckYahooAll = false;
      }
      this.chartData = this.chartData.map((it) => {
        it.isChecked = param;
        return it;
      });
    },
    totalPrice(price, sum) {
      const totalPrice = Math.floor(parseFloat(price.replace(/,/g, '')) * sum * 100) / 100;
      return toDecimal(totalPrice);
    },
    addMessage(id) {
      this.openDialogID = id;
      this.isShowDialog = true;
      this.dialogTextArea = '';
    },
    editMessage(id, message) {
      this.openDialogID = id;
      this.isShowDialog = true;
      this.dialogTextArea = message;
    },
    dialogSubmit() {
      this.isShowDialog = false;
      this.$store.commit('editCarts', { id: this.openDialogID, message: this.dialogTextArea });
    },
    sumChange(sum, id) {
      this.$store.commit('editCarts', { id, sum: sum });
    },
    checkboxChange(param) {
      this.chartData = this.chartData.slice();
    },
    deleteHandler() {
      const checkIds = this.chartData.filter((it) => it.isChecked === true).map((it) => it.id);
      if (checkIds.length === 0) {
        this.$notify({
          title: '操作失败',
          message: '尚未选取商品',
          type: 'warning',
        });
        return;
      }
      this.$store.commit('setLoading', true);
      setTimeout(() => {
        this.$store.commit('deleteCarts', checkIds);
        this.$notify({
          title: '成功',
          message: '已删除选取项目',
          type: 'success',
        });

        this.$nextTick(() => {
          this.initChartData();
          this.$store.commit('setLoading', false);
        });
      }, 200);
    },
    submitChartHandler() {
      const checkIds = this.chartData.filter((it) => it.isChecked === true).map((it) => it.id);
      if (checkIds.length === 0) {
        this.$MSG.error('尚未选取结帐商品');
        return;
      }
      if (!this.$store.state.loginData.isLogin) {
        this.$MSG.error('请先登入');
        this.$router.push({ name: 'Login' });
        return;
      }
      this.$store.commit('setLoading', true);

      setTimeout(() => {
        this.$store.commit('deleteCarts', checkIds);
        this.$store.commit('setLoading', false);
        alert('已完成订单');

        this.$nextTick(() => {
          this.initChartData();
        });
      }, 2000);
    },
    clickShopName(param) {
      const enType = StoreType.find((it) => it.label === param.type).name;
      if (param.web === 'Yahoo') {
        this.$router.push({
          name: 'YahooDetail',
          query: {
            type: enType,
            id: param.id,
          },
        });
      } else {
        this.$router.push({
          name: 'LaTenDetail',
          query: {
            type: enType,
            id: param.id,
          },
        });
      }
    },
  },
};

export default ShopCartMixin;
