<template>
  <div id="ShopCart">
    <div class="MainContainer">
      <div class="typeTitle">购物车</div>

      <div class="lineRow"> </div>

      <div class="row titleRow">
        <div class="selectBlock">
          <el-checkbox
            class="checkBox"
            size="medium"
            v-model="isCheckAll"
            @change="allCheckEvent"
          ></el-checkbox>
        </div>
        <div class="imgTitleBlock"> 全选 商品信息</div>
        <div class="storeNameBlock"></div>
        <div class="priceBlock"> 单价 </div>
        <div class="priceBlock"> 数量 </div>
        <div class="priceBlock"> 小计 </div>
        <div class="priceBlock"> 运费 </div>
        <div class="optionBlock"> 操作 </div>
      </div>

      <div class="row webRow">
        <div class="selectBlock">
          <el-checkbox
            class="checkBox"
            size="medium"
            @change="LaTenAllCheckedChange"
            v-model="isCheckLaTenAll"
          ></el-checkbox>
        </div>
        <div class="imgTitleBlock">日本亚马逊</div>
      </div>
      <div class="row storeItemRow" v-for="it in LaTenChart" :key="it.id">
        <div class="selectBlock">
          <el-checkbox
            @change="checkboxChange"
            class="checkBox"
            size="medium"
            v-model="it.isChecked"
          ></el-checkbox>
        </div>
        <div class="imgTitleBlock">
          <div class="storeItemImg">
            <img :src="require(`@/assets/store/Laten/${it.id}.jpg`)" alt="" />
          </div>
        </div>
        <div class="storeNameBlock" @click="clickShopName(it)">{{ it.name }}</div>
        <div class="priceBlock">￥ {{ it.price }}</div>
        <div class="priceBlock">
          <el-input-number
            size="small"
            v-model="it.sum"
            :min="1"
            :max="5"
            @change="(param) => sumChange(param, it.id)"
          ></el-input-number>
        </div>
        <div class="priceBlock priceColor">￥ {{ totalPrice(it.price, it.sum) }} </div>
        <div class="priceBlock"> 待报价 </div>
        <div class="optionBlock">
          <div class="optionText" @click="editMessage(it.id)" v-if="it.message === ''">
            添加备注
          </div>
          <div class="optionText" @click="editMessage(it.id, it.message)" v-else> 编辑备注 </div>
        </div>
      </div>
      <div class="row storeItemRow emptyRow" v-if="LaTenChart.length === 0"> 尚无添加商品 </div>

      <div class="row webRow">
        <div class="selectBlock">
          <el-checkbox
            class="checkBox"
            size="medium"
            @change="YahooAllCheckedChange"
            v-model="isCheckYahooAll"
          ></el-checkbox>
        </div>
        <div class="imgTitleBlock"> 日本雅虎</div>
      </div>
      <div class="row storeItemRow" v-for="it in YahooChart" :key="it.id">
        <div class="selectBlock">
          <el-checkbox
            @change="checkboxChange"
            class="checkBox"
            size="medium"
            v-model="it.isChecked"
          ></el-checkbox>
        </div>
        <div class="imgTitleBlock">
          <div class="storeItemImg">
            <img :src="require(`@/assets/store/Yahoo/${it.id}.png`)" alt="" />
          </div>
        </div>
        <div class="storeNameBlock" @click="clickShopName(it)">{{ it.name }}</div>
        <div class="priceBlock">￥ {{ it.price }}</div>
        <div class="priceBlock">
          <el-input-number
            size="small"
            v-model="it.sum"
            :min="1"
            :max="5"
            @change="(param) => sumChange(param, it.id)"
          ></el-input-number>
        </div>
        <div class="priceBlock priceColor">￥ {{ totalPrice(it.price, it.sum) }} </div>
        <div class="priceBlock"> 待报价 </div>
        <div class="optionBlock">
          <div class="optionText" @click="editMessage(it.id)" v-if="it.message === ''">
            添加备注
          </div>
          <div class="optionText" @click="editMessage(it.id, it.message)" v-else> 编辑备注 </div>
        </div>
      </div>
      <div class="row storeItemRow emptyRow" v-if="YahooChart.length === 0"> 尚无添加商品 </div>

      <div class="footerRow">
        <div class="footerLeftBlock">
          <div class="checkedNumBlock">
            {{ `已选（${isCheckedNum}）` }}
          </div>
          <div class="deleteBlock">
            <img class="deleteIcon" src="@/assets/deleteLogo.png" alt="" @click="deleteHandler" />
            <div class="deleteText" @click="deleteHandler">删除</div>
          </div>
        </div>
        <div class="footerRightBlock">
          <div class="left">
            <div class="leftTop">
              <div>商品总计：{{ totalPayment }}</div>
            </div>
            <div class="leftBottom"> 运费总计：￥00.00 </div>
          </div>
          <div class="center">
            <div class="line"></div>
          </div>
          <div class="right">
            <div class="rightLeft">应付金额：</div>
            <div class="rightCenter">￥{{ totalPayment }}</div>
            <div class="rightRight">
              <div class="addChartBtn" @click="submitChartHandler"> 结算 </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="备注" :visible.sync="isShowDialog" width="400px" center>
      <div>
        <el-input
          class="textAreaInput"
          type="textarea"
          placeholder="如果有必要，请详细注明大小，颜色，型号等等。"
          v-model="dialogTextArea"
          resize="none"
          :rows="8"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowDialog = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import ShopCartMixin from './ShopCartMixin';

  export default {
    extends: ShopCartMixin,
    name: 'ShopCartPC',
  };
</script>

<style lang="scss" scoped>
  #ShopCart {
    $OutContainerPadding: 30px;
    background: url('~@/assets/bg.png');
    .checkBox {
      margin-right: 15px;
    }
    .MainContainer {
      @include setMaxWidth;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background: white;
      padding: 0px $OutContainerPadding;
      padding-top: 60px;
      padding-bottom: 100px;
      overflow-x: auto;
      .typeTitle {
        font-size: 2rem;
        font-weight: bold;
        padding: 40px 0 15px 0;
        @media screen and(max-width:768px) {
          padding: 15px 0 10px 0;
          font-size: 16px;
        }
      }

      .lineRow {
        width: calc(100% - $OutContainerPadding);
        height: 2px;
        background: #dbdbdb80 0% 0% no-repeat padding-box;
      }

      .row {
        display: flex;
        align-items: center;
        padding: 10px 0;
        width: 1140px;

        .selectBlock {
          width: 75px;

          .el-checkbox {
            float: right;
            color: #000000;
          }
        }
        .imgTitleBlock {
          width: 120px;
          .storeItemImg {
            width: fit-content;
            width: 120px;
            height: 120px;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
        .storeNameBlock {
          width: 260px;
          padding: 0 25px;
          font-size: 0.875rem;
          cursor: pointer;
        }
        .priceBlock {
          width: 135px;
          text-align: center;
          font-size: 0.875rem;
        }
        .optionBlock {
          width: 140px;
          text-align: center;
          font-size: 0.875rem;
          .optionText {
            cursor: pointer;
          }
        }
        .priceColor {
          color: #d30000;
        }
      }
      .titleRow {
        background-color: #fcf1db;
        padding: 20px 0;
        margin-top: 40px;
        margin-bottom: 15px;
      }
      .webRow {
        margin-top: 10px;
        border: 1px solid #e0e0e0;
      }
      .storeItemRow {
        border: 1px solid #e0e0e0;
        border-top: 0px;
      }
      .emptyRow {
        color: #727272;
        display: flex;
        justify-content: center;
      }

      .footerRow {
        padding: 16px 10px;
        display: flex;
        margin-top: 35px;
        border: 1px solid #e0e0e0;
        font-size: 0.875rem;
        width: 1140px;
        .footerLeftBlock {
          width: 50%;
          display: flex;
          padding-left: 30px;
          .checkedNumBlock {
            display: flex;
            align-items: center;
          }
          .deleteBlock {
            display: flex;
            align-items: center;
            color: #6e6e6e;
            margin-left: 50px;
            .deleteIcon {
              cursor: pointer;
              width: 17px;
              height: 23px;
              margin-right: 6px;
            }
            .deleteText {
              cursor: pointer;
            }
          }
        }
        .footerRightBlock {
          width: 50%;
          display: flex;
          .left {
            width: 39%;
            display: flex;
            flex-wrap: wrap;
            .leftTop,
            .leftBottom {
              width: 100%;
            }
            .leftTop {
              margin-bottom: 16px;
              display: flex;
              align-items: flex-end;
            }
          }
          .center {
            width: 1%;
            .line {
              width: 1.5px;
              height: 70px;
              background: #dbdbdb80 0% 0% no-repeat padding-box;
            }
          }
          .right {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .rightLeft {
              width: 33%;
              text-align: center;
              font-size: 1.125rem;
            }
            .rightCenter {
              width: 33%;
              text-align: center;
              font-size: 1.125rem;
              color: #d30000;
            }
            .rightRight {
              width: 33%;
              .addChartBtn {
                background: transparent linear-gradient(180deg, #d30000 0%, #910000 100%) 0% 0%
                  no-repeat padding-box;
                border: 1px solid #930000;
                border-radius: 5px;
                color: white;
                font-size: 1.125rem;
                padding: 5px 20px;
                width: 80px;
                float: right;
                cursor: pointer;
                &:hover {
                  background: transparent linear-gradient(180deg, #d32a2a 0%, #8f3333 100%) 0% 0%
                    no-repeat padding-box;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
