<template>
  <div id="ShopCart">
    <template v-if="isMobile">
      <ShopCartMobile> </ShopCartMobile>
    </template>
    <template v-else>
      <ShopCartPC> </ShopCartPC>
    </template>
  </div>
</template>

<script>
  import ShopCartPC from './ShopCartPC.vue';
  import ShopCartMobile from './ShopCartMobile.vue';
  export default {
    name: 'ShopCart',
    components: {
      ShopCartPC,
      ShopCartMobile,
    },
    computed: {
      isMobile() {
        return this.$store.state.isMobile;
      },
    },
  };
</script>

<style lang="scss">
  #ShopCart {
    .is-checked {
      .el-checkbox__inner {
        background-color: #e25311;
        color: white;
      }
    }
    .el-checkbox__inner {
      background: #bebebe 0% 0% no-repeat padding-box;
      border: 1px solid #e0e0e0;
      width: 20px;
      height: 20px;
      font-size: 14px;
    }
    .el-checkbox__inner::after {
      width: 6px;
      top: 0px;
      height: 12px;
      left: 6px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: unset;
    }
    .el-input-number__decrease:hover:not(.is-disabled)
      ~ .el-input
      .el-input__inner:not(.is-disabled),
    .el-input-number__increase:hover:not(.is-disabled)
      ~ .el-input
      .el-input__inner:not(.is-disabled) {
      border-color: rgb(175, 175, 175);
    }
    .el-input-number__decrease:hover,
    .el-input-number__increase:hover {
      color: unset;
    }
  }
</style>

<style lang="scss" scoped>
  #ShopCart {
  }
</style>
